import React, { useState, useEffect } from 'react';
import { Vehicle } from './types';
import { Select, Button } from 'antd/lib';
const { Option } = Select;

import './VehicleReview.css';
import {Input, Modal, } from "antd";
import { ZoomInOutlined } from '@ant-design/icons';

interface VehicleReviewProps {
    read: boolean
    write: boolean
}

const VehicleReview: React.FC<VehicleReviewProps> = (props) => {
    const [vehicle, setVehicle] = useState<Vehicle | null>(null);
    const [enrollmentId, setEnrollmentId] = useState<any>(null);
    const [rejectionReason, setRejectionReason] = useState<string>('');
    const [plate, setPlate] = useState('');
    const [region, setRegion] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [vehicleTypeOptions, setVehicleTypeOptions] = useState<string[]>([]);
    const [plateVariations, setPlateVariations] = useState<string[]>(['']); // initialize with one empty string
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isRejectionModalVisible, setRejectionModalVisible] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const backendPath = 'https://api.yewfi.com'
    // const backendPath = 'http://127.0.0.1:5000'

    const fetchNextVehicle = async () => {
        if (!props.read) return;
        const response = await fetch(`${backendPath}/review/vehicle/next`);
        const data = await response.json();
        console.log(JSON.stringify(data, null, 2))
        if (data == null) {
            setVehicle(null);
            return;
        }

        setVehicle(data.vehicle);
        setPlate(data.vehicle.plate);
        setRegion(data.vehicle.region);
        setVehicleType(data.vehicle.vehicle_type);
        setEnrollmentId(data?.enrollment_id);

        if (data?.possible_variations?.length > 0) {
            setPlateVariations([...data.possible_variations, ''])
        } else {
            setPlateVariations(['']);
        }
        setValidationErrors([]);
        setRejectionReason('');
    };

    const fetchVehicleTypeOptions = async () => {
        const response = await fetch(`${backendPath}/review/vehicle/vehicle_type_options`);
        const data: string[] = await response.json();
        setVehicleTypeOptions(data);
    };

    const handleReview = async () => {
        if (!vehicle || !props.write) return;
        const updatedVehicle = { ...vehicle, plate, region, vehicle_type: vehicleType, plate_variations: plateVariations, enrollment_id: enrollmentId};
        console.log(`Updating vehicle: ${JSON.stringify(updatedVehicle, null, 2)}`)
        await fetch(`${backendPath}/review/vehicle`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedVehicle),
        });
        fetchNextVehicle();
    };

    const closeRejectionModal = () => {
        setRejectionModalVisible(false);
        setRejectionReason('');
    }

    const rejectVehicle = () => {
        if (enrollmentId) {
            setRejectionModalVisible(true); // Show the modal when enrollmentId is present
        } else {
            sendRejectionRequest(); // Otherwise, directly send the rejection request
        }
    };

    const sendRejectionRequest = async () => {
        if (!vehicle || !props.write) return;

        const payload = {
            ...vehicle,
            enrollment_id: enrollmentId,
            rejection_reason: rejectionReason
        };

        console.log(`Rejecting vehicle: ${JSON.stringify(payload, null, 2)}`);
        await fetch(`${backendPath}/review/vehicle/reject`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });

        setRejectionReason(''); // Clear the rejection reason after sending
        fetchNextVehicle();
    };

    const handleLookupClick = async (plate: string) => {
        const response = await fetch(`${backendPath}/review/vehicle/lookup/${plate}`);
        const data = await response.json();
        if (data) {
            setImageUrl(data.image); // assuming the response contains an "imageUrl" field
            setModalVisible(true);
        }
    };

    const addPlateVariation = () => {
        if (plateVariations[plateVariations.length - 1] !== '') {
            // Only add a new variation input if the last one is filled in
            setPlateVariations([...plateVariations, '']);
        }
    };


    const deletePlateVariation = (index: number) => {
        if (plateVariations.length === 1) {
            // If there's only one variation, simply clear its content
            setPlateVariations(['']);
        } else {
            // Otherwise, remove the specific variation from the list
            const newPlateVariations = [...plateVariations];
            newPlateVariations.splice(index, 1);
            setPlateVariations(newPlateVariations);
        }
    };

    const handleVariationChange = (index: number, value: string) => {
        const newPlateVariations = [...plateVariations];
        newPlateVariations[index] = value;
        setPlateVariations(newPlateVariations);
    };

    useEffect(() => {
        fetchVehicleTypeOptions();
        fetchNextVehicle();
    }, [props.read, props.write]);

    useEffect(() => {
        console.log(`vehicleType: ${vehicleType}`)
    }, [vehicleType])

    useEffect(() => {
        const errors: string[] = [];

        plateVariations.forEach((variation, index) => {
            if (variation === plate) {
                errors.push(`Variation at position ${index + 1} matches the plate`);
            }
        });

        setValidationErrors(errors);
    }, [plateVariations, plate]);



    if (!vehicle) return <div>Loading...</div>;

    return (
        <div className="review-container">
            <img src={vehicle.img} alt="Vehicle" className="vehicle-image" />
            <Input value={plate} onChange={(e) => setPlate(e.target.value.toUpperCase())} className="input-plate"
                   style={{marginTop: '10px'}} placeholder={'Plate'}
            />
            <div className="input-region-type" style={{marginTop: '20px'}}>
                <Input value={region} onChange={(e) => setRegion(e.target.value)} className="input-region" placeholder={'us-ca'}/>
                <Select value={vehicleType}
                        onChange={(value: string) => setVehicleType(value)}
                        className="input-type"
                        style={{marginLeft: '10px'}}
                        placeholder={'Body Type'}
                >
                    {vehicleType?.length > 0 &&
                        vehicleTypeOptions.map((option, index) => (
                            <Option key={option} value={option}>
                                {option}
                            </Option>
                        ))
                    }
                </Select>
            </div>
            <div style={{marginTop: '20px', width: '100%'}}>
                {plateVariations.map((variation, index) => (
                    <div key={index} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                        {variation && (
                            <Button icon={<ZoomInOutlined />} onClick={() => handleLookupClick(variation)} style={{marginRight: '10px'}}/>
                        )}
                        <Input
                            value={variation}
                            onChange={(e) => handleVariationChange(index, e.target.value.toUpperCase())}
                            className="input-variation"
                            style={{flexGrow: 1}}
                        />
                        {index === plateVariations.length - 1 && variation && (
                            <Button onClick={() => deletePlateVariation(index)} style={{marginLeft: '10px'}}>-</Button>
                        )}
                        {index === plateVariations.length - 1 && (
                            <Button onClick={addPlateVariation} icon="+" style={{marginLeft: '10px'}}>Add Variation</Button>
                        )}
                        {index !== plateVariations.length - 1 && (
                            <Button onClick={() => deletePlateVariation(index)} style={{marginLeft: '10px'}}>-</Button>
                        )}
                    </div>
                ))}
            </div>
            {validationErrors.map((error, index) => (
                <div key={index} style={{ color: 'red' }}>{error}</div>
            ))}
            <div style={{marginTop: '20px', display: 'flex', width: '100%'}}>
                <Button onClick={rejectVehicle} type={'primary'} danger block style={{marginRight: '10px'}}>
                    Reject
                </Button>
                <Button onClick={handleReview} type={'primary'} block
                        disabled={validationErrors.length > 0}>Approve</Button>
            </div>
            {imageUrl && (
                <Modal
                    title="Plate Lookup"
                    open={isModalVisible}
                    onCancel={() => {
                        setModalVisible(false);
                        setImageUrl(null);
                    }}
                    footer={[
                        <Button key="close" onClick={() => {
                            setModalVisible(false);
                            setImageUrl(null);
                        }}>
                            Close
                        </Button>
                    ]}
                >
                    <img src={imageUrl} alt="Looked up plate" style={{ width: '100%' }} />
                </Modal>
            )}
            {isRejectionModalVisible && (
                <Modal
                    title="Enter Rejection Reason"
                    open={isRejectionModalVisible}
                    onCancel={() => closeRejectionModal()}
                    footer={[
                        <Button key="cancel" onClick={() => closeRejectionModal()}>
                            Cancel
                        </Button>,
                        <Button key="done" type="primary" onClick={sendRejectionRequest}>
                            Done
                        </Button>
                    ]}
                >
                    <Input.TextArea
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        placeholder="Enter rejection reason..."
                        rows={4}
                    />
                </Modal>
            )}

        </div>
    );
};

export default VehicleReview;
